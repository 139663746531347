  <uitk-dialog [(showModal)]="showActivityDialog" uitkId="showActivityDialog" width="1024px" closeBtn="false" (onModalHidden)="closeActivityDialog()">
    
    <uitk-dialog-header>
      <button id="CloseBtn" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" type="button" (click)="closeActivityDialog()">
        Close
      </button>
      Open Activities for Asmt ID: {{assessmentId}}
    </uitk-dialog-header>
    <uitk-dialog-content uitkId="ActivityDialogContent" class="activity-content">
        <uitk-tabbed-panels uitkId="ActivityDialogPanel" >
          <uitk-tabbed-panel uitkId="TaskPanel" tabTitle="Task">
            <app-activity-task style="width:fit-content" [taskActivityList]="taskActivityList">
            </app-activity-task>
          </uitk-tabbed-panel>
          <uitk-tabbed-panel uitkId="ContactPanel" tabTitle="Contact">
            <app-activity-contact style="width:fit-content" [contactActivityList]="contactActivityList"></app-activity-contact>
          </uitk-tabbed-panel>
        </uitk-tabbed-panels>
    </uitk-dialog-content>

  </uitk-dialog>

