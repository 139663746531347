import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import {
  UITKInputDirective,
} from '@uitk/angular';
import type { SelectionModel,  } from '../../models/Iworklist-model';
import type {AutoCompleteModel} from '../../models/Iadvanced-search-model';



@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent {
  private _autoCompleteModel: AutoCompleteModel;

  @Input() set autoCompleteModel(value: AutoCompleteModel) {
    this._autoCompleteModel = value;
  }
  get autoCompleteModel() {
    return this._autoCompleteModel;
  }
  @Input() showErrorMessage:boolean;
  @Output() onSelect = new EventEmitter();
  @Output() onCleared = new EventEmitter();
  input: UITKInputDirective;
  filteredList: SelectionModel[] = [];

  onFilter(value: string) {
    value = value?.toLowerCase() ?? "";
    this.filteredList = this.autoCompleteModel.data.filter((item) => item.label?.toLowerCase().includes(value));

    if(!value || value == undefined || value.length <= 0){
      //Allow user to do cleanup
      this.onCleared.emit();
    }
  }

  onSelection(value: string) {
    this.onSelect.emit(value);
  }

  onKeyDown(){
    this.onFilter(this.autoCompleteModel.selectModel.label);
    const htmlElement : HTMLElement = document.querySelector<HTMLElement>("#" + this.autoCompleteModel.autoCompleteId + " > div > div");
    if(htmlElement != null && htmlElement != undefined){
      htmlElement.style.display = "block";
    }
  }

  @HostListener('document:click', ['$event', '$event.target'])
  handleAutoCompleteList(mevent: Event, targetElement: HTMLElement) {    
    const htmlElement : HTMLElement = document.querySelector<HTMLElement>("#" + this.autoCompleteModel?.autoCompleteId + " > div > div");    
    let isAutoCompleteControl = 
    (targetElement.id === this.autoCompleteModel.autocompleteInputId);
    
    if (isAutoCompleteControl && !this.autoCompleteModel.isDisable) {      
      this.onFilter(this.autoCompleteModel.selectModel.label);     

      if(htmlElement != null && htmlElement != undefined 
      )
      {
        htmlElement.setAttribute("style", "display:block !important");
        htmlElement.scrollTop = 0;
      }
    }
    else {
      this.filteredList = [];
      if(htmlElement != null && htmlElement != undefined){
        htmlElement.setAttribute("style", "display:none");
      }
    }
  }
}
