import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import {
  UITKTableDataSource
} from '@uitk/angular';
import { environment } from 'src/environments/environment';
import {
  GroupSelectionModel, IBasicFilter, IWorkListModel, SelectionModel, WorkListSearch,
  WorkListSearchDropdowns, StringSelectionModel, IAssignableUsers, IContactModel
} from '../models/Iworklist-model';
import { WorklistService } from '../services/worklist.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatInputModule, MatInput } from '@angular/material/input';
import { Constants, contactActivity, contactsLoadError, ContactType } from '../Constants/WorkListConstants';
import { INotificationService, notificationServiceToken } from '../shared/notification.service';
import { AutoCompleteModel, AutoSelectModel } from '../models/Iadvanced-search-model';

@Component({
  selector: 'basic-search',
  templateUrl: './basic-search.component.html',
  styleUrls: ['./basic-search.component.scss']
})
export class BasicSearchComponent implements OnInit {

  worklistSearchModel: WorkListSearch = {
    hospital: this.getEmptyAutoSelectModelObject(),
    patientFirstName: null,
    patientLastName: null,
    inUse: this.getEmptyStringSelectModelObject(),
    status: null,
    reviewType: null,
    assessmentType: null,
    payer: null,
    attendingPhysician: this.getEmptyAutoSelectModelObject(),
    caseManager: this.getEmptyAutoSelectModelObject(),
    activities: null,
    assessmentId: null,
    accountNumber: null,
    assignedTo: this.getEmptyStringSelectModelObject(),
    healthSystem: this.getEmptyAutoSelectModelObject(),
    isActiveClients: false,
    isIncludeClosed: false,
    isIncludeDeleted: false,
    fromAdmitDate: null,
    toAdmitDate: null,
    fromDischargeDate: null,
    toDischargeDate: null
  };

  workListSearchDropdownData: WorkListSearchDropdowns = {
    hospitalList: [],
    inUseList: [],
    statusList: [],
    reviewTypeList: [],
    assessmentTypeList: [],
    attendingPhysicianList: [],
    caseManagerList: [],
    activitiesList: [],
    assignedToList: [],
    healthSystemList: [],
    healthSystemHospitalList: [],
    payerList: []
  };

  private recentReviewTimeSpan: number = +(environment.myRecentReviewsTimeFrame);

  basicFilterdata: IBasicFilter = {
    assessmentTypeIds: "", status: "", hospitalId: 0, attendingPhysicianId: 0, patientFirstName: "", patientLastName: "", caseManager: "",
    payerName: "", inUse: '', assignedTo: "", recentReviewTimeSpan: this.recentReviewTimeSpan,
    accountNumber: '', isActiveClients: false, isIncludeClosed: false, isIncludeDeleted: false,
    fromAdmitDate: '', toAdmitDate: '', fromDischargeDate: '', toDischargeDate: '', healthSystem: 0,
    activities: '', isGlobalRestriction: false,isGlobalRestrictionPR: false 
  };

  tempData = new UITKTableDataSource<IWorkListModel>([]);
  initialWorkListData: IWorkListModel[] = [];
  public tableViewSelectedModel?: SelectionModel;

  public isAdmitDateRangeInvalid: boolean = false;
  public isDischargeDateRangeInvalid: boolean = false;
  public isAdmitDateStartErrorState: boolean = false;
  public isAdmitDateEndErrorState: boolean = false;
  public isDischargeDateStartErrorState: boolean = false;
  public isDischargeDateEndErrorState: boolean = false;
  contactActivitiesLength: number;

  constructor(private readonly changeDetect: ChangeDetectorRef, private readonly workListService: WorklistService, @Inject(notificationServiceToken)
  public notificationService: INotificationService) { }
  autoCompleteModelForCaseManager: AutoCompleteModel = {
    data: [],
    label: "Case Manager",
    selectModel: {
      label: "",
      value: 0
    },
    autocompleteInputId: "app-autocomplete-input-cm",
    autoCompleteId: "app-autocomplete-cm",
    autoCompleteListItemId: "app-autocomplete-list-item-cm",
    isRequired: false,
    isDisable: !(this.worklistSearchModel.hospital && this.worklistSearchModel.hospital.value > 0)
  };

  autoCompleteModelForHealthSystemName: AutoCompleteModel = {
    data: [],
    label: "Health System Name",
    selectModel: {
      label: "",
      value: 0
    },
    autocompleteInputId: "app-autocomplete-input-hsn",
    autoCompleteId: "app-autocomplete-hsn",
    autoCompleteListItemId: "app-autocomplete-list-item-hsn",
    isRequired: false,
    isDisable: false
  };
  autoCompleteModelForHospitalName: AutoCompleteModel = {
    data: [],
    label: "Hospital Name",
    selectModel: {
      label: "",
      value: 0
    },
    autocompleteInputId: "app-autocomplete-input-hn",
    autoCompleteId: "app-autocomplete-hn",
    autoCompleteListItemId: "app-autocomplete-list-item-hn",
    isRequired: false,
    isDisable: false
  };
  autoCompleteModelForAttending: AutoCompleteModel = {
    data: [],
    label: "Attending",
    selectModel: {
      label: "",
      value: 0
    },
    autocompleteInputId: "app-autocomplete-input-at",
    autoCompleteId: "app-autocomplete-at",
    autoCompleteListItemId: "app-autocomplete-list-item-at",
    isRequired: false,
    isDisable: !(this.worklistSearchModel.hospital && this.worklistSearchModel.hospital.value > 0)
  };
  autoCompleteModelForInUse: AutoCompleteModel = {
    data: [],
    label: "In Use",
    selectModel: {
      label: "",
      value: 0
    },
    autocompleteInputId: "app-autocomplete-input-iu",
    autoCompleteId: "app-autocomplete-iu",
    autoCompleteListItemId: "app-autocomplete-list-item-iu",
    isRequired: false,
    isDisable: false
  };
  autoCompleteModelForAssignedTo: AutoCompleteModel<string> = {
    data: [],
    label: "Assigned To",
    selectModel: {
      label: "",
      value: ""
    },
    autocompleteInputId: "app-autocomplete-input-ato",
    autoCompleteId: "app-autocomplete-ato",
    autoCompleteListItemId: "app-autocomplete-list-item-ato",
    isRequired: false,
    isDisable: false
  };

  autoCompleteModelForPayer: AutoCompleteModel = {
    data: [],
    label: "Payer",
    selectModel: {
      label: "",
      value: 0
    },
    autocompleteInputId: "app-autocomplete-input-payer",
    autoCompleteId: "app-autocomplete-payer",
    autoCompleteListItemId: "app-autocomplete-list-item-payer",
    isRequired: false,
    isDisable: false
  };

  ngOnInit(): void {
    this.autoCompleteModelForCaseManager.data = [];
    this.getWorklistSearchData();
    this.LoadAssignedToUsers();
  }

  public getWorklistSearchData() {
    this.workListService.getWorkListSearchData().subscribe(
      (data: any) => {
        this.workListSearchDropdownData.hospitalList = data.hospitalList;
        this.autoCompleteModelForHospitalName.data = data.hospitalList;
        this.workListSearchDropdownData.assessmentTypeList = data.assessmentTypeList;
        this.workListSearchDropdownData.caseManagerList = data.caseManagersList;
        this.workListSearchDropdownData.attendingPhysicianList = data.attendingPhysiciansList;
        this.autoCompleteModelForInUse.data = data.caseInUseUsersList;
        this.workListSearchDropdownData.inUseList = data.caseInUseUsersList;
        this.workListSearchDropdownData.healthSystemList = data.healthSystemList;
        this.autoCompleteModelForHealthSystemName.data = data.healthSystemList;
        this.workListSearchDropdownData.activitiesList = this.mapActivitiesList(data.activitiesList);
        this.workListSearchDropdownData.statusList = data.statusList;
        this.workListSearchDropdownData.healthSystemHospitalList = data.healthSystemHospitalList;
        this.autoCompleteModelForPayer.data = this.initializePayerList(data.payerList);
      }
    );
  }

  public filterValues(): IBasicFilter {
    this.basicFilterdata.hospitalId = this.worklistSearchModel.hospital.value ? this.worklistSearchModel.hospital.value : 0;
    this.basicFilterdata.patientFirstName = this.worklistSearchModel.patientFirstName ? this.worklistSearchModel.patientFirstName : "";
    this.basicFilterdata.patientLastName = this.worklistSearchModel.patientLastName ? this.worklistSearchModel.patientLastName : "";
    this.basicFilterdata.assessmentTypeIds = this.getAssessmentTypeFiltervalues();
    this.basicFilterdata.caseManager = this.worklistSearchModel.caseManager.value ? this.worklistSearchModel.caseManager.label : "";
    this.basicFilterdata.attendingPhysicianId = this.worklistSearchModel.attendingPhysician.value ? this.worklistSearchModel.attendingPhysician.value : 0;
    this.basicFilterdata.payerName = this.worklistSearchModel.payer ? this.worklistSearchModel.payer : "";
    this.basicFilterdata.inUse = this.worklistSearchModel.inUse.value ? this.worklistSearchModel.inUse.value : "";
    this.basicFilterdata.assignedTo = this.worklistSearchModel.assignedTo.value ? this.worklistSearchModel.assignedTo.value : "";
    this.basicFilterdata.accountNumber = this.worklistSearchModel.accountNumber ? this.worklistSearchModel.accountNumber : "";
    this.basicFilterdata.isActiveClients = this.worklistSearchModel.isActiveClients;
    this.basicFilterdata.isIncludeClosed = this.worklistSearchModel.isIncludeClosed;
    this.basicFilterdata.isIncludeDeleted = this.worklistSearchModel.isIncludeDeleted;
    this.basicFilterdata.fromAdmitDate = this.formatDate(this.worklistSearchModel.fromAdmitDate);
    this.basicFilterdata.toAdmitDate = this.formatDate(this.worklistSearchModel.toAdmitDate);
    this.basicFilterdata.fromDischargeDate = this.formatDate(this.worklistSearchModel.fromDischargeDate);
    this.basicFilterdata.toDischargeDate = this.formatDate(this.worklistSearchModel.toDischargeDate);
    this.basicFilterdata.healthSystem = this.worklistSearchModel.healthSystem.value ? this.worklistSearchModel.healthSystem.value : 0;
    this.basicFilterdata.activities = this.getActivitiesFiltervalues();
    this.basicFilterdata.status = this.getStatusFiltervalues();
    return this.basicFilterdata;
  }

  public clearSearchModel() {
    this.worklistSearchModel.assessmentId = null;
    this.worklistSearchModel.assessmentType = null;
    this.worklistSearchModel.status = null;
    this.worklistSearchModel.hospital = null;
    this.worklistSearchModel.patientFirstName = "";
    this.worklistSearchModel.patientLastName = "";
    this.worklistSearchModel.attendingPhysician = null;
    this.worklistSearchModel.payer = "";
    this.worklistSearchModel.accountNumber = null;
    this.worklistSearchModel.isActiveClients = false;
    this.worklistSearchModel.isIncludeClosed = false;
    this.worklistSearchModel.isIncludeDeleted = false;
    this.worklistSearchModel.fromAdmitDate = "";
    this.worklistSearchModel.toAdmitDate = "";
    this.worklistSearchModel.fromDischargeDate = "";
    this.worklistSearchModel.toDischargeDate = "";
    this.worklistSearchModel.activities = null;
    this.clearSearchFilters();
    this.isAdmitDateRangeInvalid = false;
    this.isDischargeDateRangeInvalid = false;
    this.isAdmitDateStartErrorState = false;
    this.isAdmitDateEndErrorState = false;
    this.isDischargeDateStartErrorState = false;
    this.isDischargeDateEndErrorState = false;
  }

  public clearSearchFilters() {
    this.basicFilterdata.assessmentTypeIds = "";
    this.basicFilterdata.caseManager = '';
    this.basicFilterdata.hospitalId = 0;
    this.basicFilterdata.patientFirstName = '';
    this.basicFilterdata.patientLastName = '';
    this.basicFilterdata.payerName = '';
    this.basicFilterdata.attendingPhysicianId = 0;
    this.basicFilterdata.inUse = '';
    this.basicFilterdata.assignedTo = "";
    this.basicFilterdata.accountNumber = '';
    this.basicFilterdata.isActiveClients = false;
    this.basicFilterdata.isIncludeClosed = false;
    this.basicFilterdata.isIncludeDeleted = false;
    this.basicFilterdata.fromAdmitDate = '';
    this.basicFilterdata.toAdmitDate = '';
    this.basicFilterdata.fromDischargeDate = '';
    this.basicFilterdata.toDischargeDate = '';
    this.basicFilterdata.healthSystem = 0;
    this.basicFilterdata.activities = '';
    this.resetAutoCompleteModelForCaseManager();
    this.resetAutoCompleteModelForAssignedTo();
    this.resetAutoCompleteModelForAttending();
    this.resetAutoCompleteModelForHealthSystemName();
    this.resetAutoCompleteModelForHospitalName();
    this.resetAutoCompleteModelForInUse();
    this.resetAutoCompleteModelForPayer();


  }

  public mapActivitiesList(activitiesList: any): GroupSelectionModel[] {

    let result = activitiesList.reduce(function (r, a) {
      r[a.label] = r[a.label] || [];
      r[a.label].push(a.value);
      return r;
    }, Object.create(null));
    let group: GroupSelectionModel[] = []
    for (let element in result) {
      group.push({ label: element, value: result[element] })
    };
    this.contactActivitiesLength = group.find(x => x.label == contactActivity)?.value?.length;
    return group;
  }

  public initializePayerList(payerList: SelectionModel[]): any{
    const sortedPayerList: any = [];
    sortedPayerList.push(payerList.find(p => p.label === "Medicare"));
    sortedPayerList.push(payerList.find(p => p.label === "Medicaid"));
    sortedPayerList.push(payerList.find(p => p.label === "Self Pay"));
    const payerArray = payerList.filter(p => !(p.label === "Medicare" || p.label === "Medicaid" || p.label === "Self Pay"));
    sortedPayerList.push(...payerArray);
    return sortedPayerList;
  }

  public getActivitiesFiltervalues(): string {

    let activityFilter = '';
    if (this.worklistSearchModel.activities != null) {
      for (let i = 0; i < this.worklistSearchModel.activities?.length; i++) {
        for (let j = 0; j < this.worklistSearchModel.activities[i].value.length; j++) {
          activityFilter = [activityFilter, this.worklistSearchModel.activities[i].value[j].value].join(',');
        }
      }
    }
    return activityFilter;
  }

  private formatDate(dateReceived: string): string {
    if (
      dateReceived == null ||
      new Date(dateReceived).toString() === 'Invalid Date'
    ) {
      return '';
    } else {
      const date = new Date(dateReceived);
      return ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
    }
  }

  public handleisActiveClientsToggleChange(checked) {
    this.worklistSearchModel.isActiveClients = checked;
  }

  public handleisIncludeClosedToggleChange(checked) {
    this.worklistSearchModel.isIncludeClosed = checked;
  }

  public handleisIncludeDeletedToggleChange(checked) {
    this.worklistSearchModel.isIncludeDeleted = checked;
  }

  onAdmitDateStartChange($event) {
    this.isAdmitDateRangeInvalid = false;
    this.isAdmitDateStartErrorState = false;
    if ($event?.target?.ngControl?.control?.status !== "VALID") {
      this.isAdmitDateRangeInvalid = true;
      this.isAdmitDateStartErrorState = true;
    }
    this.validateAdmitDateRange();
  }

  onAdmitDateEndChange($event) {
    this.isAdmitDateRangeInvalid = false;
    this.isAdmitDateEndErrorState = false;
    if ($event?.target?.ngControl?.control?.status !== "VALID") {
      this.isAdmitDateRangeInvalid = true;
      this.isAdmitDateEndErrorState = true;
    }
    this.validateAdmitDateRange();
  }

  onDischargeDateStartChange($event) {
    this.isDischargeDateRangeInvalid = false;
    this.isDischargeDateStartErrorState = false;
    if ($event?.target?.ngControl?.control?.status !== "VALID") {
      this.isDischargeDateRangeInvalid = true;
      this.isDischargeDateStartErrorState = true;
    }
    this.validateDischargeDateRange();
  }

  onDischargeDateEndChange($event) {
    this.isDischargeDateRangeInvalid = false;
    this.isDischargeDateEndErrorState = false;
    if ($event?.target?.ngControl?.control?.status !== "VALID") {
      this.isDischargeDateRangeInvalid = true;
      this.isDischargeDateEndErrorState = true;
    }
    this.validateDischargeDateRange();
  }

  public validateAdmitDateRange(): boolean {
    //while page load start and end dates are empty
    if (this.isAdmitDateStartErrorState === false && this.isAdmitDateEndErrorState === false &&
      (this.worklistSearchModel?.fromAdmitDate === null || this.worklistSearchModel?.fromAdmitDate?.length === 0) &&
      (this.worklistSearchModel?.toAdmitDate === null || this.worklistSearchModel?.toAdmitDate?.length === 0)) {
      this.isAdmitDateRangeInvalid = false;
    }
    //if one date is valid and another one is empty
    else if (((this.isAdmitDateStartErrorState == false && this.worklistSearchModel?.fromAdmitDate !== null) && (this.worklistSearchModel?.toAdmitDate === null || this.worklistSearchModel?.toAdmitDate?.length === 0)) ||
      ((this.isAdmitDateEndErrorState === false && this.worklistSearchModel?.toAdmitDate !== null) && (this.worklistSearchModel?.fromAdmitDate === null || this.worklistSearchModel?.fromAdmitDate?.length === 0))) {
      this.isAdmitDateRangeInvalid = true;
    }
    else if (this.isAdmitDateStartErrorState || this.isAdmitDateEndErrorState) {
      this.isAdmitDateRangeInvalid = true;
    }
    else {
      this.isAdmitDateRangeInvalid = false;
    }
    return this.isAdmitDateRangeInvalid;
  }

  public validateDischargeDateRange(): boolean {
    //while page load start and end dates are empty
    if (this.isDischargeDateStartErrorState === false && this.isDischargeDateEndErrorState === false &&
      (this.worklistSearchModel?.fromDischargeDate === null || this.worklistSearchModel?.fromDischargeDate?.length === 0) &&
      (this.worklistSearchModel?.toDischargeDate === null || this.worklistSearchModel?.toDischargeDate?.length === 0)) {
      this.isDischargeDateRangeInvalid = false;
    }
    //if one date is valid and another one is empty
    else if (((this.isDischargeDateStartErrorState == false && this.worklistSearchModel?.fromDischargeDate !== null) && (this.worklistSearchModel?.toDischargeDate === null || this.worklistSearchModel?.toDischargeDate?.length === 0)) ||
      ((this.isDischargeDateEndErrorState === false && this.worklistSearchModel?.toDischargeDate !== null) && (this.worklistSearchModel?.fromDischargeDate === null || this.worklistSearchModel?.fromDischargeDate?.length === 0))) {
      this.isDischargeDateRangeInvalid = true;
    }
    else if (this.isDischargeDateStartErrorState || this.isDischargeDateEndErrorState) {
      this.isDischargeDateRangeInvalid = true;
    }
    else {
      this.isDischargeDateRangeInvalid = false;
    }
    return this.isDischargeDateRangeInvalid;
  }

  public getStatusFiltervalues(): string {
    let statusFilter = '';
    if (this.worklistSearchModel.status != null) {
      for (let i = 0; i < this.worklistSearchModel.status?.length; i++) {
        statusFilter = [statusFilter, this.worklistSearchModel.status[i].label].join(',');
      }
      if (statusFilter.charAt(0) === ',') {
        statusFilter = statusFilter.substring(1);
      }
    }
    return statusFilter;
  }

  public getAssessmentTypeFiltervalues(): string {
    let assessmentTypeFilter = '';
    if (this.worklistSearchModel.assessmentType != null) {
      for (let i = 0; i < this.worklistSearchModel.assessmentType?.length; i++) {
        assessmentTypeFilter = [assessmentTypeFilter, this.worklistSearchModel.assessmentType[i].value].join(',');
      }
      if (assessmentTypeFilter.charAt(0) === ',') {
        assessmentTypeFilter = assessmentTypeFilter.substring(1);
      }
    }
    return assessmentTypeFilter;
  }
  public LoadAssignedToUsers() {


    this.workListService.getAssignableUsers().subscribe(
      (res: IAssignableUsers) => {
        try {
          if (res && res.data && res.data.length > 0) {

            res.data.forEach((value: StringSelectionModel, indexNumber: number, array: any[]) => {
              this.autoCompleteModelForAssignedTo.data.push(<AutoSelectModel<string>>value);
            });

          }
          this.autoCompleteModelForAssignedTo.data.splice(0, 0, { value: 'Unassigned', label: 'Unassigned' });
        }
        catch {
          this.notificationService.showError("Unable to get assignable users");
        }
      }
    );
  }

  onHealthSystemValueChange(model) {
    if (model && model.value > 0) {
      this.autoCompleteModelForHospitalName.data = <Array<AutoSelectModel>>this.workListSearchDropdownData.hospitalList.filter(x =>
        this.workListSearchDropdownData.healthSystemHospitalList
          .filter(function (list) {
            return list.healthSystemId === model.value;
          })
          .map(y => y.hospitalId)
          .includes(x.value)
      );
    }
    else {
      this.autoCompleteModelForHospitalName.data = <Array<AutoSelectModel>>this.workListSearchDropdownData.hospitalList;
    }
  }

  onHospitalValueChange(model) {

    this.resetAutoCompleteModelForAttending();
    this.resetAutoCompleteModelForCaseManager();
    if (model && model.value > 0) {
      this.autoCompleteModelForCaseManager.isDisable = false;
      this.autoCompleteModelForAttending.isDisable = false;
      this.workListService.getContacts(model.value, null).subscribe({
        next: (contactList) => {
          if (contactList && contactList.length > 0) {
            this.autoCompleteModelForCaseManager.data = <Array<AutoSelectModel>>contactList
              .sort((a, b) => a.contact.label.localeCompare(b.contact.label)).filter((x) => x.contactTypeId === ContactType.CaseManager)
              .map((x) => x.contact);
            this.autoCompleteModelForAttending.data = <Array<AutoSelectModel>>contactList
              .sort((a, b) => a.contact.label.localeCompare(b.contact.label)).filter((x) => x.contactTypeId === ContactType.Attending)
              .map((x) => x.contact);
          }
        },
        error: () => this.notificationService.showError(contactsLoadError),
      });

    }
  }

  onCaseManagerSelect(value: string) {
    //Option is selected from type ahead search textbox      
    this.worklistSearchModel.caseManager = JSON.parse(JSON.stringify(value));
  }
  handleActivitiesChange() {
    if (this.worklistSearchModel?.activities?.some(x => x.label == contactActivity)) {
      let contactActivities = this.worklistSearchModel?.activities.find(x => x.label == contactActivity);
      let x = contactActivities.value.length === this.contactActivitiesLength ? null : contactActivities.value.map((x) => x.value);
      this.workListService.sendActivityData(x);
    }
    else {
      this.workListService.sendActivityData(null);
    }
  }

  onCaseManagerSelectionCleared() {
    //Clearing exisiting slection if any    
    this.worklistSearchModel.caseManager = this.getEmptyAutoSelectModelObject();
  }

  resetAutoCompleteModelForCaseManager() {
    this.autoCompleteModelForCaseManager.selectModel = this.getEmptyAutoSelectModelObject();
    this.autoCompleteModelForCaseManager.data = [];
    this.autoCompleteModelForCaseManager.isDisable = true;
    this.onCaseManagerSelectionCleared();
  }
  onHealthSystemNameSelect(value: string) {
    //Option is selected from type ahead search textbox
    this.onHealthSystemValueChange(JSON.parse(JSON.stringify(value)));
    this.worklistSearchModel.healthSystem = JSON.parse(JSON.stringify(value));
  }
  onHealthSystemNameSelectionCleared() {
    //Clearing exisiting slection if any
    this.onHealthSystemValueChange(JSON.parse(JSON.stringify(this.getEmptyAutoSelectModelObject())));
    this.worklistSearchModel.healthSystem = this.getEmptyAutoSelectModelObject();
    this.resetAutoCompleteModelForHospitalName();
    this.resetAutoCompleteModelForCaseManager();
    this.resetAutoCompleteModelForAttending();
  }
  resetAutoCompleteModelForHealthSystemName() {
    this.autoCompleteModelForHealthSystemName.selectModel = this.getEmptyAutoSelectModelObject();
    this.onHealthSystemNameSelectionCleared();
  }
  onHospitalNameSelect(value: string) {
    //Option is selected from type ahead search textbox
    this.onHospitalValueChange(JSON.parse(JSON.stringify(value)));
    this.worklistSearchModel.hospital = JSON.parse(JSON.stringify(value));
  }
  onHospitalNameSelectionCleared() {
    //Clearing exisiting slection if any    
    this.worklistSearchModel.hospital = this.getEmptyAutoSelectModelObject();
    this.resetAutoCompleteModelForCaseManager();
    this.resetAutoCompleteModelForAttending();
  }
  resetAutoCompleteModelForHospitalName() {
    this.autoCompleteModelForHospitalName.selectModel = this.getEmptyAutoSelectModelObject();
    this.autoCompleteModelForHospitalName.isDisable = false;
    this.onHospitalNameSelectionCleared();
  }
  onAttendingSelect(value: string) {
    //Option is selected from type ahead search textbox      
    this.worklistSearchModel.attendingPhysician = JSON.parse(JSON.stringify(value));
  }
  onAttendingSelectionCleared() {
    //Clearing exisiting slection if any    
    this.worklistSearchModel.attendingPhysician = this.getEmptyAutoSelectModelObject();
  }
  resetAutoCompleteModelForAttending() {
    this.autoCompleteModelForAttending.data = [];
    this.autoCompleteModelForAttending.selectModel = this.getEmptyAutoSelectModelObject();
    this.autoCompleteModelForAttending.isDisable = true;
    this.onAttendingSelectionCleared();
  }
  onInUseSelect(value: string) {
    //Option is selected from type ahead search textbox      
    this.worklistSearchModel.inUse = JSON.parse(JSON.stringify(value));
  }
  onInUseSelectionCleared() {
    //Clearing exisiting slection if any    
    this.worklistSearchModel.inUse = this.getEmptyStringSelectModelObject();
  }
  resetAutoCompleteModelForInUse() {
    this.autoCompleteModelForInUse.selectModel = this.getEmptyAutoSelectModelObject();
    this.onInUseSelectionCleared();
  }
  onAssignedToSelect(value: string) {
    //Option is selected from type ahead search textbox      
    this.worklistSearchModel.assignedTo = JSON.parse(JSON.stringify(value));
  }
  onAssignedToSelectionCleared() {
    //Clearing exisiting slection if any    
    this.worklistSearchModel.assignedTo = this.getEmptyStringSelectModelObject();
  }
  resetAutoCompleteModelForAssignedTo() {
    this.autoCompleteModelForAssignedTo.selectModel = { label: "", value: "" };
    this.onAssignedToSelectionCleared();
  }

  onPayerSelect(payer: SelectionModel){
    this.worklistSearchModel.payer = JSON.parse(JSON.stringify(payer.label));
  }

  onPayerSelectionCleared(){
    this.worklistSearchModel.payer = "";
  }

  resetAutoCompleteModelForPayer(){
    this.autoCompleteModelForPayer.selectModel = this.getEmptyAutoSelectModelObject();
    this.onPayerSelectionCleared();
  }
  getEmptyStringSelectModelObject(): StringSelectionModel {
    return { value: '', label: '' };
  }
  getEmptyAutoSelectModelObject(): AutoSelectModel {
    return { value: 0, label: "" };
  }
}
