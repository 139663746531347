import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'keepHtml', pure: false })
export class KeepHtmlPipe implements PipeTransform {
  private readonly _sanitizer: DomSanitizer;
  constructor(sanitizer: DomSanitizer) {
    this._sanitizer = sanitizer;
  }

  transform(content) {
    return this._sanitizer.bypassSecurityTrustHtml(content);    
  }
}
