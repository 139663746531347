<div *ngFor="let contact of contactActivityList">
  <uitk-panel [open]="opencontactPanel" (openChange)="openContactChange($event)">
    <uitk-panel-header-title class="contactheadertitle">
      <h2 class="contact-header">Contact - </h2>
      <p class="header-content">{{ contact.activityName }}</p>
    </uitk-panel-header-title>
    <uitk-panel-content>
      <hr style="margin-top:0px"/> 
      <h2 class="content-header">Status</h2>
      <p style="min-height: 10px; padding-top: 1rem !important;">{{contact.activityStatusName}}</p>

      <div>
        <h2 class="content-header">Notes</h2>
        <p style="min-height: 10px; padding-top: 1rem !important;">{{contact.notes}}</p>
      </div>
    </uitk-panel-content>
  </uitk-panel>
</div>
<ng-container *ngIf="contactActivityList?.length==0">
  <div style="margin-left: 20px">
    No Records
  </div>
</ng-container>
