import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipExComponent } from './tooltip-ex/tooltip-ex.component'
import { TooltipExModule } from './tooltip-ex/tooltip-ex.module'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,  
    TooltipExModule,
  ],
  exports: [   
    TooltipExComponent,
  ],
})
export class CustomUITKModule { }
