import { Component, OnInit } from '@angular/core';
import {logosConfig, navigationBarModel } from '../Constants/NavigationBarConstants'
import { ILogoConfigModel, INavigationBarModel } from '../models/INavigtion-bar-model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
  
  public userName = '';
  public navigationBarData: Array<INavigationBarModel>;
  public logosConfig : ILogoConfigModel;
  public logoConfig = {
    logo: { path: 'assets/images/optum-logos/Optum.svg', alt: 'optum logo' }
};
  navigationTypeGlobal: string;

   
  ngOnInit() {
    this.navigationBarData = navigationBarModel;
   this.logosConfig = logosConfig;
  }

  onMenuItemClick(itemName: string) {
    switch(itemName) {
      case 'Sign Out':
        const signOutUrl =
        `${environment.apis.default.url}/account/signout?signInUrl=${environment.application.baseUrl}`;
        window.location.href = signOutUrl;
      break;
      default:
        break;
    }
  }

}
