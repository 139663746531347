import { Injectable, InjectionToken } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';

export const notificationServiceToken = new InjectionToken<INotificationService>(
  'INotificationService'
);

export interface INotificationService {
  successMessages: any;
  errorMessages: any;
  warningMessages: any;
  showError(message: string): void;
  showSuccess(message: string): void;
  showWarning(message: string): void;
  hideErrors(): void;
  hideSuccess(): void;
  hideWarnings(): void;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements INotificationService {

  constructor(private router: Router) { }

  public successMessages = {
    visible: false,
    id: 'info_msg',
    messageType: 'success',
    isCloseEnabled: true,
    closeText: 'Close',
    content: 'This is an information message',
  };

  public errorMessages = {
    visible: false,
    id: 'error_msg',
    messageType: 'error',
    isCloseEnabled: true,
    closeText: 'Close',
    content: 'This is an error message'
  };
  public warningMessages = {
    visible: false,
    id: 'warning_msg',
    messageType: 'warning',
    isCloseEnabled: true,
    closeText: 'Close',
    content: 'This is an warning message'
  };

  successTimeout: Subscription = new Subscription();
  errorTimeout: Subscription = new Subscription();

  showWarning(message: string) {
    this.errorTimeout.unsubscribe();
    this.warningMessages.content = message;
    this.warningMessages.visible = true;
  }
  
  showError(message: string) {
    // If we show a new message we need to cancel any existing
    // requests to close the toast mesage
    this.errorTimeout.unsubscribe();
    this.errorMessages.content = message;
    this.errorMessages.visible = true;
  }

  showSuccess(message: string) {
    // If we show a new message we need to cancel any existing
    // requests to close the toast mesage
    this.successTimeout.unsubscribe();

    this.successMessages.content = message;
    this.successMessages.visible = true;
    // tslint:disable-next-line: deprecation
    this.successTimeout = timer(5000).subscribe(() => {
      this.successMessages.visible = false;
    });
  }

  hideErrors() {
    this.errorMessages.visible = false;
  }

  hideSuccess() {
    this.successMessages.visible = false;
  }
  hideWarnings() {
    this.warningMessages.content = "";
    this.warningMessages.visible = false;
  }
}
