<uitk-form-field>
    <label uitkLabel>{{autoCompleteModel.label}}</label>
    <input [attr.id]="autoCompleteModel.autocompleteInputId" id="{{autoCompleteModel.autocompleteInputId}}" uitkInput #input="uitkInput" #myAutocompleteInput="ngModel"
        [(ngModel)]="autoCompleteModel.selectModel.label" (keydown)="onKeyDown()" name="myAutocompleteInput" required="{{autoCompleteModel.isRequired}}" disabled="{{autoCompleteModel.isDisable}}" />
        <uitk-form-field-error *ngIf="autoCompleteModel.isRequired && showErrorMessage && (autoCompleteModel.selectModel.label === undefined || autoCompleteModel.selectModel.label === null || autoCompleteModel.selectModel.label === '')">
            <span>{{autoCompleteModel.requiredMesage}}</span>
          </uitk-form-field-error>
    <uitk-autocomplete [attr.id]="autoCompleteModel.autoCompleteId" [charTriggerLength]="0" (filter)="onFilter($event)"
        (onSelect)="onSelection($event)" [uitkInput]="input" >
        <ul uitkAutocompleteList>
            <li [uitkAutocompleteListItem]="filteredListItem" *ngFor="let filteredListItem of filteredList">
                <span [attr.id]="autoCompleteModel.autoCompleteListItemId" class="uitk-c-autocomplete__item-label">{{ filteredListItem.label }}</span>
            </li>
        </ul>
    </uitk-autocomplete>
</uitk-form-field>
