import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-contact',
  templateUrl: './activity-contact.component.html',
  styleUrls: ['./activity-contact.component.scss']
})
export class ActivityContactComponent  {
  public opencontactPanel: boolean = false;
  @Input() contactActivityList: any;



  onContactPanelClick($event) {
// this method is defined but not used anywhere writing comment to resolve the sonar issue..

  }

  openContactChange($event) {
// this method is defined but not used anywhere writing comment to resolve the sonar issue..

  }
}
