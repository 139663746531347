
export enum Roles {
  CaseView = "Case View",
  CaseManager = "Case Manager",
  Physician = "Physician",
  CaseAdvisorUser = "UR Advisor User",
  Elmah = "ElmahAccess",
  GlobalAdmin = "Global Admin",
  SystemAdmin = "System Admin",
  Reporting = "Reporting",
  CaseIntake = "Case Intake",
  OnSitePA = "OnSite Physician",
  ReadOnlyUser = "Read Only User",
  NurseCaseView = "Nurse Case View",
  ReportOnly = "Report Only",
  OpasMCS = "OPAS MCS",
  IsOPASTenant = "Is OPAS Tenant",
  LimitToSingleEncounter = "Limit to Single Encounter",
  CardiovascularProcedure = "Cardiovascular Procedure",
  CaseAdvisorAdministration = "Case Advisor Administration",
  CompleteAssessment = "Complete Assessment",
  CompleteAssessmentOnBehalfOf = "Complete Assessment on behalf of",
  ConcurrentDenial = "Concurrent Denial",
  DiscardSentLetter = "Discard Sent Letter",
  FullEditOfCompletedAssessment = "Full Edit of Completed Assessment",
  GenerateLetter = "Generate Letter",
  PDSDiscardLetterSent = "PDSDiscardLetterSent",
  PDSQueueLetter = "PDSQueueLetter",
  PhysicianDocumentationServices = "Physician Documentation Services",
  PostDischarge = "Post Discharge",
  Procedure = "Procedure",
  QueueLetter = "Queue Letter",
  Rehab = "Rehab",
  ResetAssessment = "Reset Assessment",
  UnlockAssessment = "Unlock Assessment"
}

export enum Status {
  Closed = "Closed",
  ClosedWithoutReview = "Closed without Review",
  Completed = "Completed",
  Deleted = "Deleted",
  IntakeAutoNew = "Intake Auto New",
  AACompleted = "AA Completed",
  AACompletedNeedsPrep = "AA Completed-Needs Prep",
  ClientOverride = "Client Override",
  Expedited = "Expedited",
  IntakeClinicalsToBeEntered = "Intake Clinicals to be Entered",
  IntakeCompleted = "Intake Completed",
  IntakePended = "Intake Pended",
  NurseActive = "Nurse Active",
  NursePrepped = "Nurse Prepped",
  NurseUnprepped = "Nurse Unprepped",
  OnSiteReviewPending = "OnSite Review Pending",
  PAActive = "PA Active",
  Ready = "Ready",
  RequestedAssessmentUpdated = "Requested Assessment Updated",
  DC_SentToFLR = "DC - Sent to FLR",
  FLRActive = "FLR Active",
  FLRPended = "FLR Pended"
}

export enum Permissions {
  CaseViewAccess = "CaseAdvisor.CaseView.CaseViewAccess",
  ConcurrentIntake = "CaseAdvisor.Intake.ConcurrentIntake",
  UnlockAssessment = "CaseAdvisor.CaseView.UnlockAssessment",
  OspaPermission = "CaseAdvisor.Dispenser.OnSitePA",
  MyNextReview = "CaseAdvisor.CaseView.MyNextReview"
}
