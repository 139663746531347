import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TooltipExComponent} from './tooltip-ex.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        TooltipExComponent,
    ],
    exports: [
        TooltipExComponent,
    ],
})
export class TooltipExModule { }
