<div class="smallFont" id="searchPanelDiv">
  <div class="uitk-l-grid__row uitk-u-flex__align--left">
    <div class="uitk-l-grid__col max-width-12nHalfPercent">
      <app-auto-complete  [autoCompleteModel]="autoCompleteModelForHealthSystemName" (onSelect)="onHealthSystemNameSelect($event)" (onCleared)="onHealthSystemNameSelectionCleared()">
      </app-auto-complete>
    </div>
    <div class="uitk-l-grid__col max-width-12nHalfPercent">
      <app-auto-complete [autoCompleteModel]="autoCompleteModelForHospitalName" (onSelect)="onHospitalNameSelect($event)" (onCleared)="onHospitalNameSelectionCleared()">
      </app-auto-complete>
    </div>
    <div class="uitk-l-grid__col max-width-12nHalfPercent">
      <uitk-form-field>
        <label  uitkLabel>Patient First Name</label>
        <input id="patient-first-txt"
               uitkInput
               style="height: 30px !important;"
               [(ngModel)]="worklistSearchModel.patientFirstName"
               name="patientFirstName"
               #patientNameInput="ngModel" />
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col max-width-12nHalfPercent">
      <uitk-form-field>
        <label  uitkLabel>Patient Last Name</label>
        <input id="patient-last-txt"
               uitkInput
               style="height: 30px !important;"
               [(ngModel)]="worklistSearchModel.patientLastName"
               name="patientLastName"
               #patientNameInput="ngModel" />
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col max-width-12nHalfPercent">
      <uitk-form-field>
        <label  uitkLabel>Account Number</label>
        <input id="accountNumberId-txt" [ngClass]="{'uitk-c-form__control--error' : worklistSearchModel?.accountNumber?.length > 0 && worklistSearchModel?.accountNumber?.length < 4}"
               uitkInput
               style="height: 30px !important;"
               [(ngModel)]="worklistSearchModel.accountNumber"
               name="accountNumberId-input"
               #assessmentIdInput="ngModel" />
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col date-range-align">
      <label  uitkLabel>Admit Date</label>
      
        <mat-form-field id="pocid" class="mat-form-field" style="background-color: #fff;" [ngClass]="{'mat-form__control--error' : isAdmitDateRangeInvalid}">
         <mat-label></mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="start date" [(ngModel)]="worklistSearchModel.fromAdmitDate" (dateChange)="onAdmitDateStartChange($event)" />
            <input matEndDate placeholder="end date" [(ngModel)]="worklistSearchModel.toAdmitDate" (dateChange)="onAdmitDateEndChange($event)" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker" >
            <mat-icon id="caliconid" matDatepickerToggleIcon class="uitk-icon uitk-icon-calendar lg-icon"></mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </div>
    <div class="uitk-l-grid__col date-range-align" >
      <!-- style="margin-left: -2.3rem !important;" -->
      <label  uitkLabel>Discharge Date</label>
      
      <mat-form-field id="pocid" class="mat-form-field" style="background-color: #fff;" [ngClass]="{'mat-form__control--error' : isDischargeDateRangeInvalid}">
        <mat-label></mat-label>
        <mat-date-range-input [rangePicker]="picker1">
          <input matStartDate placeholder="start date" [(ngModel)]="worklistSearchModel.fromDischargeDate" (dateChange)="onDischargeDateStartChange($event)" />
          <input matEndDate placeholder="end date" [(ngModel)]="worklistSearchModel.toDischargeDate" (dateChange)="onDischargeDateEndChange($event)" />
        </mat-date-range-input> 
        <mat-datepicker-toggle matSuffix [for]="picker1" >
          <mat-icon matDatepickerToggleIcon class="uitk-icon uitk-icon-calendar lg-icon" ></mat-icon>          
        </mat-datepicker-toggle>
        <mat-date-range-picker #picker1></mat-date-range-picker>
      </mat-form-field>
    </div>  
             
  </div>

  <div class="uitk-l-grid__row uitk-u-flex__align--left">
        
    <div class="uitk-l-grid__col max-width-12nHalfPercent">
      <app-auto-complete  [autoCompleteModel]="autoCompleteModelForPayer" (onSelect)="onPayerSelect($event)" (onCleared)="onPayerSelectionCleared()">
      </app-auto-complete>
    </div>
    <div class="uitk-l-grid__col max-width-12nHalfPercent">
      <app-auto-complete [autoCompleteModel]="autoCompleteModelForCaseManager" (onSelect)="onCaseManagerSelect($event)" (onCleared)="onCaseManagerSelectionCleared()">
      </app-auto-complete>
    </div>
    <div class="uitk-l-grid__col max-width-12nHalfPercent">
      <app-auto-complete [autoCompleteModel]="autoCompleteModelForAttending" (onSelect)="onAttendingSelect($event)" (onCleared)="onAttendingSelectionCleared()">
      </app-auto-complete>
    </div>
    <div class="uitk-l-grid__col min-width-18-Percent">
      <uitk-form-field>
        <label  uitkLabel>Assessment Type</label>
        <uitk-dropdown uitkId="assessmenttype-grouped-dropdown"
                       [isMultiSelect]="true"
                       [items]="workListSearchDropdownData.assessmentTypeList"
                       fieldsetLabel="Fieldset Legend"
                       [showSelectAll]="true"
                       name="assessmentType-dropdown"
                       [(ngModel)]="worklistSearchModel.assessmentType">
        </uitk-dropdown>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col min-width-18-Percent" >
      <uitk-form-field>
        <label  uitkLabel>Status</label>
        <uitk-dropdown uitkId="status-grouped-dropdown"
                       [isMultiSelect]="true"
                       [items]="workListSearchDropdownData.statusList"
                       fieldsetLabel="Fieldset Legend"
                       [showSelectAll]="true"
                       name="status-dropdown"
                       [(ngModel)]="worklistSearchModel.status">
        </uitk-dropdown>
      </uitk-form-field>
    </div>  
    <div class="uitk-l-grid__col max-width-12nHalfPercent">
      <app-auto-complete  [autoCompleteModel]="autoCompleteModelForInUse" (onSelect)="onInUseSelect($event)" (onCleared)="onInUseSelectionCleared()">
      </app-auto-complete>
    </div>
    <div class="uitk-l-grid__col max-width-12nHalfPercent">
      <app-auto-complete  [autoCompleteModel]="autoCompleteModelForAssignedTo" (onSelect)="onAssignedToSelect($event)" (onCleared)="onAssignedToSelectionCleared()">
      </app-auto-complete>
    </div>      
    
   
  </div>

  <div class="uitk-l-grid__row uitk-u-flex__align--left">
    
    <div class="uitk-l-grid__col width-betwn-16rem-18rem">
      <uitk-form-field>
        <label  uitkLabel>Activities</label>
        <uitk-dropdown id="activities-grouped-dropdown"
                       [isMultiSelect]="true"
                       [items]="workListSearchDropdownData.activitiesList"
                       fieldsetLabel="Fieldset Legend"
                       [showSelectAll]="true"
                       name="activities-dropdown"
                       [(ngModel)]="worklistSearchModel.activities"
                       (ngModelChange)="handleActivitiesChange()">
        </uitk-dropdown>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col max-width-9rem">
      <uitk-form-field>
        <label  uitkLabel>Active Clients Only</label>
        <uitk-toggle-switch [uitkId]="'isActiveClients'"
                            [checked]="worklistSearchModel.isActiveClients"
                            (onChange)="handleisActiveClientsToggleChange($event)">
          <span *ngIf="worklistSearchModel.isActiveClients"> Yes </span>
          <span *ngIf="!worklistSearchModel.isActiveClients"> No </span>
        </uitk-toggle-switch>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col max-width-9rem">
      <uitk-form-field>
        <label  uitkLabel>Include Closed</label>
        <uitk-toggle-switch [uitkId]="'isIncludeClosed'"
                            [checked]="worklistSearchModel.isIncludeClosed"
                            (onChange)="handleisIncludeClosedToggleChange($event)">
          <span *ngIf="worklistSearchModel.isIncludeClosed"> Yes </span>
          <span *ngIf="!worklistSearchModel.isIncludeClosed"> No </span>
        </uitk-toggle-switch>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col max-width-9rem">
      <uitk-form-field>
        <label  uitkLabel>Include Deleted</label>
        <uitk-toggle-switch [uitkId]="'isIncludeDeleted'"
                            [checked]="worklistSearchModel.isIncludeDeleted"
                            (onChange)="handleisIncludeDeletedToggleChange($event)">
          <span *ngIf="worklistSearchModel.isIncludeDeleted"> Yes </span>
          <span *ngIf="!worklistSearchModel.isIncludeDeleted"> No </span>
        </uitk-toggle-switch>
      </uitk-form-field>
    </div>    

  </div>
</div>
