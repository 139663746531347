<div class="smallFont" id="AdvanceSearchPanelDiv" style="margin-left: -0.5rem !important;">
  <div class="uitk-l-grid__row">
    <div class="uitk-l-grid__col min-max-width-21Percent">
      <uitk-form-field>
        <label uitkLabel>Optum Clinical Group (OCG)</label>
        <uitk-dropdown uitkId="ocg-singleselect" #singleSelect="ngModel" [items]="advancedSearchDropdownsData.ocgList"
          [enableSelectFlag]="true" name="ocg-dropdown" [(ngModel)]="advancedSearchModel.ocg">
        </uitk-dropdown>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col">
      <uitk-form-field>
        <label uitkLabel>Initial Order</label>
        <uitk-dropdown uitkId="initialorder-singleselect" #singleSelect="ngModel"
          [items]="advancedSearchDropdownsData.initialOrderList" [enableSelectFlag]="true" name="initialorder-dropdown"
          [(ngModel)]="advancedSearchModel.initialOrder">
        </uitk-dropdown>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col">
      <uitk-form-field>
        <label uitkLabel>Recommendation</label>
        <uitk-dropdown uitkId="recommendation-singleselect" #singleSelect="ngModel" [items]="advancedSearchDropdownsData.recommendationList"
          [enableSelectFlag]="true" name="recommendation-dropdown" [(ngModel)]="advancedSearchModel.recommendation">
        </uitk-dropdown>
      </uitk-form-field>
    </div>    
    <div class="uitk-l-grid__col">
      <uitk-form-field>
        <label uitkLabel>Member ID</label>
        <input id="memberid-txt" uitkInput style="height: 30px !important;" [(ngModel)]="advancedSearchModel.memberId"
          name="memberid" #patientNameInput="ngModel" />
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col">
      <uitk-form-field>
        <label uitkLabel>Intake Source</label>
        <uitk-dropdown uitkId="intakesource-singleselect" #singleSelect="ngModel"
          [items]="advancedSearchDropdownsData.intakeSourceList" [enableSelectFlag]="true" name="intakesource-dropdown"
          [(ngModel)]="advancedSearchModel.intakeSource">
        </uitk-dropdown>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col">
      <uitk-form-field>
        <label uitkLabel>Task Status</label>
        <uitk-dropdown uitkId="taskstatus-singleselect" #singleSelect="ngModel"
          [items]="advancedSearchDropdownsData.taskStatusList" [enableSelectFlag]="true" name="taskstatus-dropdown"
          [(ngModel)]="advancedSearchModel.taskStatus">
        </uitk-dropdown>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col">
      <uitk-form-field>
        <label uitkLabel>FLR Criteria Status</label>
        <uitk-dropdown uitkId="flrcriteriastatus-singleselect" #singleSelect="ngModel"
          [items]="advancedSearchDropdownsData.flrCriteriaStatusList" [enableSelectFlag]="true"
          name="flrcriteriastatus-dropdown" [(ngModel)]="advancedSearchModel.flrCriteriaStatus">
        </uitk-dropdown>
      </uitk-form-field>
    </div>

  </div>
  <div class="uitk-l-grid__row">
    
    <div class="uitk-l-grid__col min-max-width-21Percent">
      <uitk-form-field>
        <label uitkLabel>Contact Reason</label>
        <uitk-dropdown id="contactreason-multiselect" [isMultiSelect]="true"
          [items]="advancedSearchDropdownsData.contactReasonList" fieldsetLabel="Fieldset Legend" [showSelectAll]="true"
          name="contactreason-dropdown" [(ngModel)]="advancedSearchModel.contactReason">
        </uitk-dropdown>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col">
      <uitk-form-field>
        <label uitkLabel>Contact Method</label>
        <uitk-dropdown id="contactmethod-multiselect" [isMultiSelect]="true"
          [items]="advancedSearchDropdownsData.contactMethodList" fieldsetLabel="Fieldset Legend" [showSelectAll]="true"
          name="contactmethod-dropdown" [(ngModel)]="advancedSearchModel.contactMethod">
        </uitk-dropdown>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col min-max-width-21Percent">
      <uitk-form-field>
        <label uitkLabel>Contact Status</label>
        <uitk-dropdown id="contactstatus-multiselect" [isMultiSelect]="true"
          [items]="advancedSearchDropdownsData.contactStatusList" fieldsetLabel="Fieldset Legend" [showSelectAll]="true"
          name="contactstatus-dropdown" [(ngModel)]="advancedSearchModel.contactStatus">
        </uitk-dropdown>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col min-max-width-22Percent">
      <uitk-form-field>
        <label uitkLabel>Contact Responses</label>
        <uitk-dropdown id="contactresponse-multiselect" [isMultiSelect]="true"
          [items]="advancedSearchDropdownsData.contactResponseList" fieldsetLabel="Fieldset Legend"
          [showSelectAll]="true" name="contactresponse-dropdown" [(ngModel)]="advancedSearchModel.contactResponse">
        </uitk-dropdown>
      </uitk-form-field>
    </div>
    <div class="uitk-l-grid__col min-max-width-22Percent">
      <uitk-form-field>
        <label uitkLabel>Contact Outcome</label>
        <uitk-dropdown id="contactoutcome-multiselect" [isMultiSelect]="true"
          [items]="advancedSearchDropdownsData.contactOutcomeList" fieldsetLabel="Fieldset Legend"
          [showSelectAll]="true" name="contactoutcome-dropdown" [(ngModel)]="advancedSearchModel.contactOutcome">
        </uitk-dropdown>
      </uitk-form-field>
    </div>

  </div>
  <div class="uitk-l-grid__row uitk-u-flex__align--left">


    <div id="openactivityid" class="uitk-l-grid__col--2 max-width-9rem">
      <uitk-form-field>
        <label uitkLabel>Open Activity Only</label>
        <uitk-toggle-switch [uitkId]="'isOpenActivityOnly'" [checked]="advancedSearchModel.isOpenActivityOnly"
          (onChange)="handleisOpenActivityOnlyToggleChange($event)">
          <span *ngIf="advancedSearchModel.isOpenActivityOnly"> Yes </span>
          <span *ngIf="!advancedSearchModel.isOpenActivityOnly"> No </span>
        </uitk-toggle-switch>
      </uitk-form-field>
    </div>
    <div id="dischargesummaryid" class="uitk-l-grid__col--2 max-width-11Rem">
      <uitk-form-field>
        <label uitkLabel>Discharge Summary Only</label>
        <uitk-toggle-switch [uitkId]="'isDischargeSummaryOnly'" [checked]="advancedSearchModel.isDischargeSummaryOnly"
          (onChange)="handleIsDischargeSummaryOnlyToggleChange($event)">
          <span *ngIf="advancedSearchModel.isDischargeSummaryOnly"> Yes </span>
          <span *ngIf="!advancedSearchModel.isDischargeSummaryOnly"> No </span>
        </uitk-toggle-switch>
      </uitk-form-field>
    </div>
    <div id="payerrefusalid" class="uitk-l-grid__col--2 max-width-9rem">
      <uitk-form-field>
        <label uitkLabel>Payer Refusal Only</label>
        <uitk-toggle-switch [uitkId]="'isPayerRefusalOnly'" [checked]="advancedSearchModel.isPayerRefusalOnly"
          (onChange)="handleIsPayerRefusalOnlyToggleChange($event)">
          <span *ngIf="advancedSearchModel.isPayerRefusalOnly"> Yes </span>
          <span *ngIf="!advancedSearchModel.isPayerRefusalOnly"> No </span>
        </uitk-toggle-switch>
      </uitk-form-field>
    </div>
  </div>
</div>
