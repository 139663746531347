import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export function Authentication(
  http: HttpClient
): () => Promise<boolean> {
  return async () => {

    const isAuthenticated = await http
      .get<boolean>(
        environment.apis.default.url + '/api/AuthorizationApi/IsAuthenticated',
        { withCredentials: true }
      )
      .toPromise();

    console.log('ssoUrl: ' + environment.ssoUrl);
    console.log(environment.ssoUrl + '?redirectUrl=' + encodeURIComponent(window.location.href));
    console.log('isAuthenticated: ' + isAuthenticated);

    if (!isAuthenticated) {
      const url = environment.ssoUrl + '?redirectUrl=' + encodeURIComponent(window.location.href);
      window.location.href = url;
      throw new Error('Not authenticated');
    }

    return false;
  };
}
