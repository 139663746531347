import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, Input, OnInit } from '@angular/core';
import { timer } from 'rxjs';


@Component({
    selector: 'uitk-tooltip-ex',
    templateUrl: './tooltip-ex.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})

export class TooltipExComponent implements OnInit {

    /**
     * Sets where in relation to its target content the tooltip should appear. [ 'top', 'bottom', 'bottom-left', 'bottom-right', 'top-left', 'top-right']
     * @required
     */
    @Input('tooltipPlacement') public tooltipPlacement: string;

    /**
     * This specifies the content of tooltip
     * @required
     */
    @Input('tooltipContent') public tooltipContent: string;

    /**
     * This specifies the tooltip for ellipses.
     * If the inner content is text this will truncate the text with ellipses and place the full text in the tooltip.
     */
    @Input('truncateText') public truncateText: boolean;

    
    @Input('forceInnerContent') public forceInnerContent: boolean;

    /**
     * This specifies the type of tooltip.
     * If set to 'mini' the mini tooltip displays, it is a styling change for more compact tooltips.
     * Leave empty for the standard tooltip styles.
     */
    @Input('uitkTooltipType') uitkTooltipType: 'default' | 'mini' = 'default';

    /**
     * This specifies the id of tooltip for A11y purpose
     * @required
     */
    @Input('tooltipId') public tooltipId: string;

    /**
     * If the tooltip position should change depending on the position on the screen. Defaults to true.
     */
    @Input('isDynamicallyPositioned') public isDynamicallyPositioned: boolean = true;


    public tooltipPosition: string;
    @ContentChild('insideMessage', { static: false }) insideMessage;
    @ContentChild('tooltipCustomText', { static: false }) tooltipCustomText;

    ngOnInit(): void {
        this.tooltipPosition = this.tooltipPosition || 'top';

        /**
         * @Depracate 'right' and 'left' on 7.0.0
         * Make it backwards compatible with 'right' and 'left' configuration.
         */
        if (this.tooltipPlacement === 'right' || this.tooltipPlacement === 'left') {
            this.tooltipPlacement = 'bottom-' + this.tooltipPlacement;
        }
        this.getPositionClass(false);
    }


    getPositionClass(holdOpen: boolean) {
        this.tooltipPosition = ' tk-tooltip-' + this.tooltipPlacement;
        if(holdOpen)
        {
            this.tooltipPosition += ' tooltip-held-open'
        }
    }

    LogShown() {
        console.log('shown');        
    }
}
