import { Environment } from '@abp/ng.core';

const baseUrl = '#{FrontendBaseUrl}#';

export const environment = {
  production: true,
  ssoUrl: '#{ssoUrl}#',
  caseViewUrl: '#{caseViewUrl}#',
  caseIntakeUrl: '#{caseIntakeUrl}#' ,
  identityServiceUrl: '#{identityServiceUrl}#',
  myRecentReviewsTimeFrame: '#{myRecentReviewsTimeFrame}#',
  maxCaseInUseCount: '#{maxCaseInUseCount}#',
  
  timeoutInMinutes: '#{timeoutInMinutes}#',
  timeoutWarningDurationInSeconds: '#{timeoutWarningDurationInSeconds}#',
  keepAliveIntervalInMinutes: '#{keepAliveIntervalInMinutes}#',

  application: {
    baseUrl,
    name: 'Worklist',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: 'https://localhost:44379',
    redirectUri: baseUrl,
    clientId: 'Worklist_App',
    responseType: 'code',
    scope: 'offline_access Worklist',
  },
  apis: {
    default: {
      url: '#{BackendBaseUrl}#',
      rootNamespace: 'OPAS.RMS.Worklist',
    },
  },
} as OpasEnvironment;

export interface OpasEnvironment extends Environment{
  ssoUrl: string;
  caseViewUrl: string;
  caseIntakeUrl: string;
  myRecentReviewsTimeFrame: string;
  maxCaseInUseCount: string;
  timeoutInMinutes: string;
  timeoutWarningDurationInSeconds: string;
  keepAliveIntervalInMinutes: string;
  identityServiceUrl: string;
};
