<div *ngFor="let task of taskActivityList">
  <uitk-panel uitkId="uitkpanel" [open]="opentaskPanel" (openChange)="openTaskChange($event)">
    <uitk-panel-header-title class="taskheadertitle">
      <h2 class="task-header">Task - </h2>
      <p class="header-content">{{ task.activityName }}</p>
    </uitk-panel-header-title>
    <uitk-panel-content>
      <div>
        <hr style="margin-top:0px" />
        <h2 class="content-header">Assigned To</h2>
          <p style="min-height: 10px; padding-top: 1rem !important;"> {{ task.assignedToUserName }}</p>
      </div>
        <div>
          <h2 class="content-header">Notes</h2>
          <p style="min-height: 10px; padding-top: 1rem !important;"> {{task.notes}}</p>
        </div>
    </uitk-panel-content>
  </uitk-panel>
</div>
<ng-container *ngIf="taskActivityList?.length==0">
  <div style="margin-left: 20px">
    No Records
  </div> 
</ng-container>
