import { Component, Inject, OnInit } from '@angular/core';
import { INotificationService, notificationServiceToken } from './shared/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',

})
export class AppComponent implements OnInit {
  public defaultLabel: string;
  constructor(
    
    @Inject(notificationServiceToken)
    public notificationService: INotificationService) { }

  ngOnInit(): void {
    this.defaultLabel = 'Welcome to WorkList Setup..';
  }
  

}
