import {Status} from '../common/Enums';

export const Constants = {
  Search_Result: 'Search All',
  My_Reviews: 'My Cases',
  My_Recent_Reviews: 'My Recent Cases',
  Global_Restriction_Role_Name: 'GlobalRestriction',
  Global_Restriction_PR_Role_Name: 'GlobalRestrictionPR',
}

export const EditAssessmentPermittedStatuses: string[] = [
  Status.ClosedWithoutReview,
  Status.IntakeAutoNew,
  Status.AACompleted,
  Status.AACompletedNeedsPrep,
  Status.ClientOverride,	
  Status.Expedited,
  Status.IntakeClinicalsToBeEntered,	
  Status.IntakeCompleted,
  Status.IntakePended,
  Status.NurseActive,
  Status.NursePrepped,	
  Status.NurseUnprepped,	
  Status.OnSiteReviewPending,
  Status.PAActive,	
  Status.Ready,
  Status.RequestedAssessmentUpdated,
  Status.DC_SentToFLR,
  Status.FLRActive,
  Status.FLRPended
]
export const ReadOnlyOpasStatuses: string[] =

[
    Status.Completed,
    Status.Deleted,
    Status.Closed,
    Status.ClosedWithoutReview
];

export const StatusesForDraftAssessment: string[] = [
  Status.IntakeClinicalsToBeEntered,
  Status.IntakeAutoNew,
]

export enum ContactType {
  CaseManager = 1,
  Attending = 2
}

export const contactsLoadError: string = "Error while fetching contacts.";

export const taskOpen: string ="Open";
export const taskClosed : string = "Closed";
export const contactActivity: string = "Contact";
export const taskActivity: string = "Task";
export const PageTitle: string = "Worklist";
