import { Injectable } from '@angular/core';
import { ITimeoutConfigFactory, ITimeoutConfiguration } from '@optum-opas/timeout-component';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
  })
  export class ConfigurationService {
  }

export const timeoutConfigFactory: ITimeoutConfigFactory = {
    getConfig: new Promise<ITimeoutConfiguration>((resolve, reject) => {
            const keepAliveOpasCaseViewUrl = `${environment.apis.default.url}/api/AuthorizationApi/IsAuthenticated`;
            const keepAliveIdentityUrl = `${environment.identityServiceUrl}/api/AuthenticationApi/IsAuthenticated`;

            resolve({
                keepAliveIntervalInMinutes: +environment.keepAliveIntervalInMinutes,
                keepAliveUrls: [keepAliveOpasCaseViewUrl, keepAliveIdentityUrl],
                logOutUrl: `${environment.apis.default.url}/account/signout?signInUrl=${environment.application.baseUrl}`,
                timeoutInMinutes: +environment.timeoutInMinutes,
                timeoutWarningDurationInSeconds: +environment.timeoutWarningDurationInSeconds
            });
        })
}