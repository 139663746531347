import { INavigationBarModel, ILogoConfigModel } from '../models/INavigtion-bar-model';

export const logoPath = "./assets/images/optum-logos/Optum.svg";
export const altSmallLogo = "optum logo";
export const altMediumLogo = "ui toolkit logo";
export const altLargeLogo = "optum ui toolkit";

export const logosConfig: ILogoConfigModel = {
  small: { path: logoPath, alt: altSmallLogo },
  medium: { path: logoPath, alt: altMediumLogo },
  large: {
    path: logoPath,
    alt: altLargeLogo,
  },
};

export const logoConfig = {
  logo: { path: 'assets/images/optum-logos/Optum.svg', alt: 'optum logo' },
};
export const navigationBarModel: Array<INavigationBarModel> = [
  // { routeName: 'RMS Worklist', routeLink: '' },
  // { routeName: 'Reporting', routeLink: '' },
  // { routeName: 'Placeholder', routeLink: '' },
  // { routeName: 'Admin', routeLink: '' },
  { routeName: 'Sign Out', routeLink: '' },
];
