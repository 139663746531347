import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    UITKTableDataSource
} from '@uitk/angular';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { taskClosed, taskOpen } from '../Constants/WorkListConstants';
import { AdvancedSearchDropdowns, AdvancedSearchModel, IAdvanceFilter } from '../models/Iadvanced-search-model';
import { ContactFilterDropdownModel, ContactMappingModel, IWorkListModel, SelectionModel, WorkListSearch, WorkListSearchDropdowns } from '../models/Iworklist-model';
import { WorklistService } from '../services/worklist.service';


@Component({
  selector: 'advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {

  advancedSearchModel: AdvancedSearchModel = {
    ocg: null,
    initialOrder: null,
    recommendation: null,
    memberId: '',
    intakeSource: null,
    taskStatus: null,
    contactReason: null,
    contactMethod: null,
    contactStatus: null,
    contactResponse: null,
    contactOutcome: null,
    isOpenActivityOnly: false,
    flrCriteriaStatus: null,
    isDischargeSummaryOnly: false,
    isPayerRefusalOnly: false
  };

  advancedSearchDropdownsData: AdvancedSearchDropdowns = {
    ocgList: [],
    initialOrderList: [],
    recommendationList: [],
    intakeSourceList: [],
    taskStatusList: [],
    contactReasonList: [],
    contactMethodList: [],
    contactStatusList: [],
    contactResponseList: [],
    contactOutcomeList: [],
    flrCriteriaStatusList: []
  }

  advancedFilterData: IAdvanceFilter = { initialOrderId: 0, intakeSourceId: 0, memberId: '', ocgId: 0, recommendationId: 0, isOpenActivityOnly: false, taskStatus: '', contactMethod: '', contactOutcome: '', contactReason: '', contactResponse: '', contactStatus: '', isDischargeSummaryOnly: false, isPayerRefusalOnly: false };

  contactMappings: ContactMappingModel = {contactMethodList:[],contactOutcomeList:[],contactReasonList:[],contactResponseList:[],contactStatusList:[]};

  contactSubscription: Subscription;

  constructor(private readonly changeDetect: ChangeDetectorRef, private readonly workListService: WorklistService) { }

  ngOnInit(): void {    
    this.getAdvancedSearchDropDownData();
    this.subscribeToContactSubject();
  }

  
  public getAdvancedSearchDropDownData() {
    this.workListService.getAdvancedSearchDropDownData().subscribe(
      (data: any) => {
        this.advancedSearchDropdownsData.initialOrderList = data.initialOrderList;
        this.advancedSearchDropdownsData.recommendationList = data.recommendationList;
        this.advancedSearchDropdownsData.intakeSourceList = data.intakeSourceList;
        this.advancedSearchDropdownsData.ocgList = data.ocgList;
        this.advancedSearchDropdownsData.taskStatusList = [{value:0,label:taskClosed},{value:1,label:taskOpen}];
        this.advancedSearchDropdownsData.flrCriteriaStatusList = data.flrCriteriaStatusList;
        this.contactMappings.contactMethodList = data.contactMethodList;
        this.contactMappings.contactOutcomeList = data.contactOutcomeList;
        this.contactMappings.contactResponseList = data.contactResponseList;
        this.contactMappings.contactStatusList = data.contactStatusList;
        this.contactMappings.contactReasonList = data.contactReasonList;
        this.prepareContactDropdownData();
      }
    );
  }
  public subscribeToContactSubject(){
    this.contactSubscription = this.workListService.getContactSubjectAsObservable().subscribe((idArray:number[]) =>
    { 
     if(idArray!=null) 
     {
       this.filterContactDropdownData(idArray); 
     }
     else 
     {
       this.prepareContactDropdownData();
     }
    }
     );
  }
  public prepareContactDropdownData()
  {
  this.advancedSearchDropdownsData.contactMethodList = this.contactMappings?.contactMethodList?.map((x)=> ({label:x?.data?.label, value:x?.data?.value}));
  this.advancedSearchDropdownsData.contactOutcomeList = this.contactMappings?.contactOutcomeList?.map((x)=> ({label:x?.data?.label, value:x?.data?.value}));
  this.advancedSearchDropdownsData.contactResponseList = this.contactMappings?.contactResponseList?.map((x)=> ({label:x?.data?.label, value:x?.data?.value}));
  this.advancedSearchDropdownsData.contactStatusList = this.contactMappings?.contactStatusList?.map((x)=> ({label:x?.data?.label, value:x?.data?.value}));
  this.advancedSearchDropdownsData.contactReasonList = this.contactMappings?.contactReasonList?.map((x)=> ({label:x?.data?.label, value:x?.data?.value}));
  }
  
  public filterContactDropdownData(idArray:number[])
  {
    this.advancedSearchDropdownsData.contactResponseList= this.filterContactMappingList(this.contactMappings.contactResponseList,idArray);
    this.advancedSearchDropdownsData.contactOutcomeList= this.filterContactMappingList(this.contactMappings.contactOutcomeList,idArray);
    this.advancedSearchDropdownsData.contactMethodList = this.filterContactMappingList(this.contactMappings.contactMethodList,idArray);
    this.advancedSearchDropdownsData.contactReasonList = this.filterContactMappingList(this.contactMappings.contactReasonList,idArray);
    this.advancedSearchDropdownsData.contactStatusList = this.filterContactMappingList(this.contactMappings.contactStatusList,idArray);
    this.clearSearchModelContactDropdowns();
  }

  public filterContactMappingList(contactDropdownList:ContactFilterDropdownModel[],idArray:number[]): SelectionModel[]
  {
      return contactDropdownList?.filter((x)=> x?.mappedActivityTypeIds?.some((id)=> idArray?.indexOf(id)>=0))?.map((x) => ({label:x?.data?.label,value:x?.data?.value}));
  }

  public filterValues(): IAdvanceFilter {
    this.advancedFilterData.initialOrderId = this.advancedSearchModel?.initialOrder?.value ? this.advancedSearchModel?.initialOrder?.value : 0;
    this.advancedFilterData.intakeSourceId = this.advancedSearchModel?.intakeSource?.value ? this.advancedSearchModel?.intakeSource?.value : 0;
    this.advancedFilterData.memberId = this.advancedSearchModel?.memberId ? this.advancedSearchModel?.memberId : '';
    this.advancedFilterData.ocgId = this.advancedSearchModel?.ocg?.value ? this.advancedSearchModel?.ocg?.value : 0;
    this.advancedFilterData.recommendationId = this.advancedSearchModel?.recommendation?.value ? this.advancedSearchModel?.recommendation?.value : 0;
    this.advancedFilterData.isOpenActivityOnly = this.advancedSearchModel.isOpenActivityOnly;
    this.advancedFilterData.taskStatus = this.advancedSearchModel?.taskStatus?.value !=null ? this.advancedSearchModel.taskStatus.label : "";
    this.advancedFilterData.contactMethod = this.getContactFilterValues(this.advancedSearchModel.contactMethod);
    this.advancedFilterData.contactReason = this.getContactFilterValues(this.advancedSearchModel.contactReason);
    this.advancedFilterData.contactResponse = this.getContactFilterValues(this.advancedSearchModel.contactResponse);
    this.advancedFilterData.contactOutcome = this.getContactFilterValues(this.advancedSearchModel.contactOutcome);
    this.advancedFilterData.contactStatus = this.getContactFilterValues(this.advancedSearchModel.contactStatus);
    this.advancedFilterData.flrCriteriaStatus = this.advancedSearchModel?.flrCriteriaStatus?.value ? this.advancedSearchModel?.flrCriteriaStatus?.value : 0;
    this.advancedFilterData.isDischargeSummaryOnly = this.advancedSearchModel.isDischargeSummaryOnly;
    this.advancedFilterData.isPayerRefusalOnly = this.advancedSearchModel.isPayerRefusalOnly;
    return this.advancedFilterData;
  }

  public clearSearchModel() {
    this.advancedSearchModel.initialOrder = null;
    this.advancedSearchModel.intakeSource = null;
    this.advancedSearchModel.ocg = null;
    this.advancedSearchModel.recommendation = null;
    this.advancedSearchModel.memberId = '';
    this.advancedSearchModel.isOpenActivityOnly = false;
    this.advancedSearchModel.taskStatus =null;
    this.advancedSearchModel.flrCriteriaStatus = null;
    this.advancedSearchModel.isDischargeSummaryOnly = false;
    this.advancedSearchModel.isPayerRefusalOnly = false;
    this.clearSearchModelContactDropdowns();
    this.clearSearchFilters();
  }
  public clearSearchModelContactDropdowns()
  {
    this.advancedSearchModel.contactMethod=null;
    this.advancedSearchModel.contactResponse=null;
    this.advancedSearchModel.contactStatus=null;
    this.advancedSearchModel.contactOutcome=null;
    this.advancedSearchModel.contactReason=null;
  }
  public clearSearchFilters() {
    this.advancedFilterData.initialOrderId = 0;
    this.advancedFilterData.intakeSourceId = 0;
    this.advancedFilterData.memberId = '';
    this.advancedFilterData.ocgId = 0;
    this.advancedFilterData.recommendationId = 0;
    this.advancedFilterData.isOpenActivityOnly = false;
    this.advancedFilterData.taskStatus = '';
    this.advancedFilterData.contactMethod='';
    this.advancedFilterData.contactResponse='';
    this.advancedFilterData.contactReason='';
    this.advancedFilterData.contactStatus='';
    this.advancedFilterData.contactOutcome='';
    this.advancedFilterData.flrCriteriaStatus = 0;
    this.advancedFilterData.isDischargeSummaryOnly = false;
    this.advancedFilterData.isPayerRefusalOnly = false;
  }

  handleisOpenActivityOnlyToggleChange(checked) {
    this.advancedSearchModel.isOpenActivityOnly = checked;
  }
  
  handleIsDischargeSummaryOnlyToggleChange(checked) {
    this.advancedSearchModel.isDischargeSummaryOnly = checked;
  }

  handleIsPayerRefusalOnlyToggleChange(checked) {
    this.advancedSearchModel.isPayerRefusalOnly = checked;
  }

  public ClearAdvanceSearchControls() {
    this.getAdvancedSearchDropDownData();
  }
  public getContactFilterValues(contactSelection: SelectionModel[]): string {
    let contactFilter = '';
    if (contactSelection != null) {
      for (let i = 0; i < contactSelection.length; i++) {
        contactFilter = [contactFilter, contactSelection[i].value].join(',');
      }
    }
    return contactFilter;
  }
}
