import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { contactActivity, taskActivity } from '../Constants/WorkListConstants';

@Component({
  selector: 'app-activity-dialog',
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.scss']
})
export class ActivityDialogComponent implements OnInit {
  @Input() showActivityDialog: boolean;
  @Input() activityDetailsList: any[];
  @Input() assessmentId: number;
  @Output() closeActivityDialogEvent = new EventEmitter<boolean>();
  public taskActivityList = [];
  public contactActivityList = [];

  ngOnInit(): void {
    if (this.activityDetailsList != undefined && this.activityDetailsList != null && this.activityDetailsList.length > 0) {
      this.taskActivityList = this.activityDetailsList.filter(x => x.activityCategory == taskActivity &&  x.closedOn == null);
      this.contactActivityList = this.activityDetailsList.filter(x => x.activityCategory == contactActivity &&  x.closedOn == null);
    }  
  }

  closeActivityDialog() {
    this.closeActivityDialogEvent.emit(false);
  }
}
