<div id="globalpanel" (click)="onGlobalSearchPanelClick($event)" class="uitk-l-grid__row uitk-l-grid__row--no-gutter uitk-u-flex__align--left uitk-u-flex__align--center-y">
  <div class="uitk-l-grid__col">
    <uitk-form-field>
      <label class="global-search-label" id="asmtoracct"  uitkLabel>Asmt ID</label>
      <input id="searchtype-txt"  (keydown)="onAcctKeydown($event)"  [ngClass]="{'uitk-c-form__control--error' : searchField?.toString() == '0' || searchField?.toString()?.length > 9}"
             uitkInput
             [(ngModel)]="searchField"
             name="searchtype-input"
             #assessmentIdInput="ngModel" />
    </uitk-form-field>
  </div>
  <div  class="uitk-l-grid__col" style="min-width: fit-content !important;">
        <label class="global-search-label" id="createddateid" uitkLabel>Created Date</label>
        <mat-form-field id="createddaterangeid" class="mat-form-field mat-date-range-align"
          [ngClass]="{'mat-form__control--error' : isCreatedDateRangeInvalid}">
          <mat-date-range-input [rangePicker]="picker">
            <mat-label></mat-label>
            <input matStartDate placeholder="start date" [(ngModel)]="createdDateRangeModel.fromDate"
              (dateChange)="onCreatedDateStartChange($event)" />
            <input matEndDate placeholder="end date" [(ngModel)]="createdDateRangeModel.toDate"
              (dateChange)="onCreatedDateEndChange($event)" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker" >
            <mat-icon matDatepickerToggleIcon class="uitk-icon uitk-icon-calendar lg-icon"></mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
      
        </mat-form-field>
        
  </div>
  <div class="uitk-l-grid__col" style="min-width: fit-content !important;">
    <uitk-form-field>
      <label class="global-search-label" uitkLabel >Table View</label>
      <uitk-dropdown uitkId="singleselect-ReviewType" id="singleselect-ReviewType"
                     #singleSelect="ngModel"
                     [items]="tableViewTypes"
                     [enableSelectFlag]="false"
                     name="template-example"
                     [(ngModel)]="tableViewSelectedModel"
                     (onChange)="onTableViewDropdownChange($event)">
      </uitk-dropdown>
    </uitk-form-field>
  </div>
</div>


