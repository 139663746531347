import { CoreModule } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UITKDialogModule, UITKInputModule, UITKNavigationModule, UITKNotificationModule, UITKSelectModule, UITKTableModule, UITKTooltipModule, UITKTabbedPanelsModule, UITKIconModule } from '@uitk/angular';
import { WorklistComponent } from './worklist/worklist.component';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UITKPanelModule, UITKDropdownModule, UITKDatePickerModule, UITKLoadingIndicatorModule, UITKToggleSwitchModule,UITKPageNotificationModule } from '@uitk/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoCacheHeadersInterceptor } from './services/no-cache.service';
import { APP_INITIALIZER } from '@angular/core';
import { Authentication } from './services/authentication.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component'
import { NotificationService, notificationServiceToken } from './shared/notification.service';
import { BasicSearchComponent } from './basic-search/basic-search.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { CustomUITKModule } from './custom-uitk/custom-uitk.module';
import { TimeoutComponent } from './timeout/timeout.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SharedModule } from '../app/shared/shared.module';
import { TimeoutModule, TIMEOUT_CONFIG_TOKEN } from '@optum-opas/timeout-component'
import { timeoutConfigFactory } from './services/configuration.service';
import { ActivityDialogComponent } from './activity-dialog/activity-dialog.component';
import { ActivityTaskComponent } from './activity-task/activity-task.component';
import { ActivityContactComponent } from './activity-contact/activity-contact.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UITKInputModule,
    NgxsModule.forRoot(),
    UITKTableModule,
    CommonModule,
    HttpClientModule,
    UITKPanelModule,
    FormsModule,
    UITKDropdownModule,
    UITKSelectModule,
    UITKDatePickerModule,
    InfiniteScrollModule,
    UITKLoadingIndicatorModule,
    UITKToggleSwitchModule,
    UITKDialogModule,
    UITKTabbedPanelsModule,
    UITKNavigationModule,
    UITKNotificationModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    UITKTooltipModule,
    CustomUITKModule,
    UITKPageNotificationModule,
    NgIdleKeepaliveModule.forRoot(),
    SharedModule,
    TimeoutModule,
    UITKIconModule
  ],
  declarations: [
    AppComponent, 
    WorklistComponent, 
    NavigationBarComponent, 
    BasicSearchComponent, 
    GlobalSearchComponent, 
    AdvancedSearchComponent, 
    KeepHtmlPipe, 
    TimeoutComponent,
    ActivityDialogComponent,
    ActivityTaskComponent,
    ActivityContactComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: Authentication,
      multi: true,
      deps: [HttpClient],
    },
    {
      provide: notificationServiceToken,
      useClass: NotificationService,
      multi: false,
    },
    {
        provide: TIMEOUT_CONFIG_TOKEN,
        useValue: timeoutConfigFactory
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { } 
