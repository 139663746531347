import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-task',
  templateUrl: './activity-task.component.html',
  styleUrls: ['./activity-task.component.scss']
})
export class ActivityTaskComponent  {
  public opentaskPanel: boolean = false;
  @Input() taskActivityList: any;


  
  onTaskPanelClick($event) {
// this method is defined but not used anywhere writing comment to resolve the sonar issue..

  }

  openTaskChange($event) {
// this method is defined but not used anywhere writing comment to resolve the sonar issue..
  }
}
